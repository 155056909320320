import { graphql } from "gatsby";
import React, { useContext, useEffect } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import {
  mapEdgesToNodes,
  toPlainText,
  capitalizeFirstLetter,
} from "../lib/helpers";
import { videoMetaData } from "./video-bulk";
import { SiteContext } from "../components/global/site-context";
import {
  ResourcesHeader,
  ResourcesList,
  SectionWrapper,
} from "../components/sections";

export const query = graphql`
  query CategoryTemplateQuery($id: String!, $_id: String!) {
    category: sanityCategory(id: { eq: $id }) {
      _type
      _id
      status
      title
      titlePublic
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...SanityImage
      }
      slug {
        current
      }
      isSeries
      #hideNav
      customPath
      graphic {
        ...SanityImageSimple
      }
      sections {
        ... on SanityPageComponent {
          _type
          name
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          layout
          background
          padding
          container
          mainImage {
            ...SanityImageSimple
          }
          category
          effect
        }
        ... on SanityPageSectionSimple {
          _type
          _rawContent(resolveReferences: { maxDepth: 5 })
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          layout
          background
          padding
          container
        }
      }
    }
    seriesVideos: allSanityVideo(
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        series: { elemMatch: { id: { eq: $id } } }
        status: { in: ["published", "hidden"] }
      }
    ) {
      edges {
        node {
          ...cardNodeVideo
        }
      }
    }
    seriesVideosBulk: allGoogleSpreadsheetWwWebsiteContentVideos(
      sort: { fields: [publishDate], order: DESC }
      filter: { status: { eq: "published" }, series: { eq: $_id } }
    ) {
      edges {
        node {
          videoId
          slug
          status
          series
          title
          playlist
          community
          devRel
          gtm
          publishDate
        }
      }
    }
    seriesPosts: allSanityPost(
      limit: 72
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        categories: { elemMatch: { id: { in: [$id] } } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    seriesStories: allSanityStory(
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        categories: { elemMatch: { id: { in: [$id] } } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
    allStories: allSanityStory(
      sort: { fields: [_createdAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
  }
`;

const TYPE_NAME_MAP = new Map([
  ["customers", "Customer stories"],
  ["blog", "Blog posts"],
  ["signals", "Signal guides"],
]);

const CategoryTemplate = ({ data = {}, pageContext }) => {
  const site = useContext(SiteContext);
  const { title, _rawExcerpt, mainImage, titlePublic } = data.category || {};

  const theDocs = mapEdgesToNodes(data.seriesVideos)
    .concat(mapEdgesToNodes(data.seriesVideosBulk).map(videoMetaData))
    .concat(mapEdgesToNodes(data.seriesPosts))
    .concat(
      site.isActive("storiesInBlog") && data.seriesStories
        ? mapEdgesToNodes(data.seriesStories)
        : []
    )
    .concat(
      site.isActive("storiesInBlog") &&
        pageContext?._id === "6d1042dd-59ad-481b-9257-a209f67eec42"
        ? mapEdgesToNodes(data.allStories)
        : []
    )
    .sort((a, b) =>
      (a.publishedAt || a._createdAt) < (b.publishedAt || b._createdAt) ? 1 : -1
    );

  const compositeTitle = `${data.category ? titlePublic || title : ""}${
    !data.category?.isSeries && !data.category?.customPath
      ? pageContext.type
        ? data.category
          ? ` ${
              TYPE_NAME_MAP.has(pageContext.type)
                ? TYPE_NAME_MAP.get(pageContext.type).toLowerCase()
                : pageContext.type
            }`
          : TYPE_NAME_MAP.get(pageContext.type) ||
            capitalizeFirstLetter(pageContext.type)
        : " resources"
      : ""
  }`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutContainer
      doc={{ ...data.category, backgroundColor: "lightLavender" }}
    >
      <PageHeadMeta
        title={compositeTitle}
        description={toPlainText(_rawExcerpt)}
        image={mainImage}
        doc={data.category}
      />
      <article>
        <SectionWrapper layout="centered" padding="tight" container="narrow">
          <ResourcesHeader
            title={compositeTitle}
            tag={data.category}
            type={pageContext.type}
          />
        </SectionWrapper>
        <SectionWrapper background="lavenderUltraLight" padding="tight">
          <ResourcesList tag={data.category} type={pageContext.type} />
          <div className="h-12 lg:h-24"></div>
        </SectionWrapper>
      </article>
    </LayoutContainer>
  );
};

export default CategoryTemplate;
